<template>
  <el-main>
    <div class="centerwrap">
      <!-- <h2 class="gobackpage">
        <router-link
          tag="span"
          :to="{name:'specialColumnArticle',params:{Identify: special.Identify}}"
        >
          <i class="iconlujingbeifen1 iconfont"></i>返回专栏主页
        </router-link>
      </h2> -->
      <div class="centerbox">
        <div class="topbox">
          <div class="titlebox">
            <h2 class="titleh2" v-text="article.Article || '无标题'"></h2>
            <div class="creatorbox">
              <img :src="article.Avatar" alt class="timg" />
              <span class="creator">{{article.Username}}</span>
              <span class="timer">{{article.Mtime*1000 | dateformat}}</span>
            </div>
          </div>
          <span class="iconbox">
            <i class="iconfont iconshanchu3" @click="handelDelBook(article.Artid)" v-if="iscreator==1"></i>
            <i>
              <el-popover placement="bottom" title="版权" width="200" trigger="hover" content="归作者所有">
                <i slot="reference" class="iconfont icontishi"></i>
              </el-popover>
            </i>
            <!-- <i class="iconfont iconbianzu6"></i>
            <i class="iconfont iconxingzhuang4"></i> -->
            <!-- <i > -->
            <router-link
             v-if="iscreator==1"
              :to="{name:'specialColumnEdit',query:{artid:artid}}"
              tag="i"
              class="iconfont iconbianji2"
            ></router-link>
            <!-- </i> -->
          </span>
        </div>
        <div class="textbox" v-html="article.Content"></div>
        <div class="teambox" v-if="flag==2">
          <router-link tag="span" :to="{name:'notesDetail'}" class="left">
            <img :src="special.Cover" alt />
          </router-link>
          <div class="right">
            <h2 class="titleh2">
              {{special.Special}}
              <el-button type="primary" class="addteam" plain round size="mini">订阅</el-button>
            </h2>
            <p class="textp">{{special.Overview}}</p>
          </div>
        </div>
        <div class="commentbox">
          <h2 class="titleh2">全部评论</h2>
          <div class="sendcomform">
            <el-form :inline="true" class="comform" :model="commentForm" ref="commentFormRef">
              <el-form-item class="inputb" prop="commentinp" :class="commentinpError?'is-error':''">
                <el-input placeholder="写下你的评论..." v-model="commentForm.commentinp" ref="commentinp"></el-input>
                <p v-show="commentinpError" class="errorp">评论不得为空</p>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btnb"
                  @click="handelForm('commentFormRef','commentinp','commentinpError',
                  {
                    ctype: 2,
                    objectid:article.Artid,
                    pid:0,
                    content:commentForm.commentinp,
                    touid:0
                  })"
                >发布</el-button>
              </el-form-item>
            </el-form>
          </div>
          <ul class="commentlist" v-infinite-scroll="load" infinite-scroll-disabled="disabledpage">
            <li v-for="(item,index) in listData">
              <div class="firstcom">
                <span class="imgboxleft">
                  <img :src="item.Avatar" alt />
                </span>
                <div class="textboxcenter">
                  <router-link class="titleh2" tag="h2" to>{{item.Username}}</router-link>
                  <div class="text">
                    <span class="left">{{item.Ctime}}</span>
                  </div>
                  <p class="textp">{{item.Content}}</p>
                  <div class="setbox">
                    <span class="left" @click="handelCommentSupport(item.Id)">
                      <i class="iconfont iconxingzhuang21"></i>
                      赞 ({{item.Support}})
                    </span>
                    <span class="left" @click="handelReplay(item.Id)">
                      <i class="iconfont iconliaotian1"></i>
                      回复
                    </span>
                    <span
                      class="left"
                      v-show="item.delflag==1"
                      @click="handelCommentLeave(item.Id)"
                    >
                      <i class="iconfont iconshanchu3"></i>
                      删除
                    </span>
                  </div>
                  <el-form
                    :inline="true"
                    class="comform"
                    :class="active==item.Id?( commentinpError ? 'is-error active' :'active'):''"
                    :model="commentForm"
                    ref="replayFormRef"
                  >
                    <el-form-item class="inputb">
                      <el-input
                        placeholder="写下你的评论..."
                        v-model="replayinp"
                        :ref="'replayinp' + item.Id"
                      ></el-input>
                      <p v-show="commentinpError" class="errorp">评论不得为空</p>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        class="btnb"
                        @click="handelForm('replayFormRef','replayinp'+item.Id,'commentinpError',
                          {
                            ctype: 2,
                            objectid:article.Artid,
                            pid:item.Id,
                            content:replayinp,
                            touid:item.Uid
                      },'rep')"
                      >发布</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <ul class="commentlist" v-if="item.Child.length">
                <li v-for="(itemli,indexli) in item.Child">
                  <div class="firstcom">
                    <span class="imgboxleft">
                      <img :src="itemli.Avatar" alt />
                    </span>
                    <div class="textboxcenter">
                      <router-link class="titleh2" tag="h2" to>{{itemli.Username}}</router-link>
                      <div class="text">
                        <span class="left">{{itemli.Ctime}}</span>
                      </div>
                      <p class="textp">
                        <span class="blue">@{{itemli.Tousername}}</span>
                        {{itemli.Content}}
                      </p>
                      <div class="setbox">
                        <span class="left" @click="handelCommentSupport(itemli.Id)">
                          <i class="iconfont iconxingzhuang21"></i>
                          赞 ({{itemli.Support}})
                        </span>
                        <span class="left" @click="handelReplay(itemli.Id)">
                          <i class="iconfont iconliaotian1"></i>
                          回复
                        </span>
                        <span
                          class="left"
                          v-show="itemli.delflag==1"
                          @click="handelCommentLeave(itemli.Id)"
                        >
                          <i class="iconfont iconshanchu3"></i>
                          删除
                        </span>
                      </div>
                      <el-form
                        :inline="true"
                        class="comform"
                        :class="active==itemli.Id?( commentinpError ? 'is-error active' :'active'):''"
                      >
                        <el-form-item class="inputb">
                          <el-input
                            placeholder="写下你的评论..."
                            v-model="replayinp"
                            :ref="'replayinp' + itemli.Id"
                          ></el-input>
                          <p v-show="commentinpError" class="errorp">评论不得为空</p>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="primary"
                            class="btnb"
                            @click="handelForm('replayFormRef','replayinp'+itemli.Id,'commentinpError',
                          {
                            ctype: 2,
                            objectid:article.Artid,
                            pid:item.Id,
                            content:replayinp,
                            touid:itemli.Uid
                      },'rep')"
                          >发布</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <p v-if="loading" class="loadtextp">加载中...</p>
          <div v-else>
            <p v-if="noMore" class="loadtextp">没有更多了</p>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import {
  specialArticleRequest,
  specialCommentRequest,
  commentPublishRequest,
  commentSupportRequest,
  commentLeaveRequest,
  specialArticleDelRequest
} from "@/api/project.js";

import TinymceEditor from "@/components/tinymceEditor"; //编辑器
import MavonEditor from "@/components/mavonEditor"; //编辑器
import { TurndownService } from "@/assets/js/turndown.js";
import moment from "moment";
import Prism from "prismjs";
import "prismjs/themes/prism-coy.css";
export default {
  components: {
    TinymceEditor, //编辑器
    MavonEditor //编辑器
  },
  data() {
    return {
      Content: "",
      // isDel: true,
      isEditor: false,
      disabled: false,
      inittac: {
        height: "500px"
      },
      Editor: 0, //文章默认编辑器
      editorIndex: 0, //整本书默认编辑器
      Markdown: "",
      curHeight: "300px",
      article: {},
      special: {},
      listData: [],
      commentForm: {
        commentinp: ""
      },
      commentinpError: false,
      active: -1,
      replayinp: "",
      loading: false,
      totalCount: 0,
      pageCount: 0,
      page: 0,
      notitle: false,
      flag: 0,
      iscreator:0
    };
  },
  created() {
    this.getSpecialArticle();
    // this.setEditorHeight();
    // this.getCommentList();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getSpecialArticle(secid) {
      try {
        var _this = this;
        const result = await specialArticleRequest({
          artid: this.artid
        });
        if (result.status == 200) {
          if (result.data) {
            this.article = result.data.article;
            this.special = result.data.special;
            this.flag = result.data.flag;
            this.Editor=result.data.article.Editor;
            this.iscreator=result.data.iscreator;
          }
        }
        setTimeout(() => {
          Prism.highlightAll();
        }, 100);
      } catch (err) {}
    },
    handelTab(index, a) {
      this[a] = index;
    },
    setEditorHeight() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = h - 61; //减去页面上固定高度height
    },
    async getCommentList(page) {
      try {
        const result = await specialCommentRequest({
          page: page,
          ctype: 2,
          objectid: this.artid
        });
        if (result.status == 200) {
          if (result.data) {
            this.loading = false;
            this.listData = this.listData.concat(result.data.list);
            this.totalCount = result.data.totalCount;
            this.pageCount = result.data.pageCount;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    load() {
      this.page++;
      this.loading = true;
      this.getCommentList(this.page);
    },
    async subcommentForm(comdata, rep) {
      try {
        const result = await commentPublishRequest({
          ctype: comdata.ctype,
          objectid: comdata.objectid,
          pid: comdata.pid,
          content: comdata.content,
          touid: comdata.touid
        });
        if (result.status == 200) {
          this.$message({
            message: `恭喜你，${result.message}`,
            type: "success"
          });

          if (rep) {
            this.active = -1;
            this.listData.some((key, index, arr) => {
              if (comdata.pid === key.Id) {
                key.Child.unshift(result.data);
                this.replayinp = "";
              }
              return comdata.pid === key.Id;
            });
          } else {
            this.listData.unshift(result.data);
            this.commentForm.commentinp = "";
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    handelForm(formName, inp, commentinpError, comdata, rep) {
      var _this = this;
      if (rep) {
        var length = this.$refs[inp][0].value.length;
      } else {
        var length = this.$refs[inp].value.length;
      }
      if (length) {
        this[commentinpError] = false;
        if (rep) {
          this.subcommentForm(comdata, rep);
        } else {
          this.subcommentForm(comdata);
        }
      } else {
        this[commentinpError] = true;
        setTimeout(function() {
          _this[commentinpError] = false;
        }, 3000);
        return false;
      }
    },
    handelReplay(index) {
      if (this.active == index) {
        this.active = -1;
      } else {
        this.active = index;
      }
    },
    async handelCommentSupport(commentid) {
      var _this = this;
      try {
        const result = await commentSupportRequest({
          commentid: commentid
        });
        if (result.status == 200) {
          if (result.message) {
            this.$message({
              message: `恭喜你，${result.message}`,
              type: "success"
            });
          }
          isAdd(_this.listData);
          function isAdd(arr) {
            arr.some((key, index, arr) => {
              if (commentid === key.Id) {
                key.Support++;
              } else {
                if (key.Child) isAdd(key.Child);
              }
              return commentid === key.Id;
            });
          }
        } else {
          this.$message({
            message: `${result.message}`,
            type: "warning"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async handelCommentLeave(commentid) {
      var _this = this;
      try {
        const result = await commentLeaveRequest({
          commentid: commentid
        });
        if (result.status == 200) {
          if (result.message) {
            this.$message({
              message: `恭喜你，${result.message}`,
              type: "success"
            });
          }
          isDel(_this.listData);
          function isDel(arr) {
            arr.some((key, index, arr) => {
              if (commentid === key.Id) {
                arr.splice(index, 1);
              } else {
                if (key.Child) isDel(key.Child);
              }
              return commentid === key.Id;
            });
          }
        } else {
          this.$message({
            message: `${result.message}`,
            type: "warning"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    prev() {
      this.$router.go(-1);
    },
    async specialArticleDel(id) {
      try {
        const result = await specialArticleDelRequest({
          artid: id
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.$router.push({
            name: "specialColumnArticle",
            params: { Identify: this.special.Identify }
          });
        } else {
          this.$message({
            type: "error",
            message: result.message
          });
        }
      } catch (err) {}
    },
    handelDelBook(id) {
      var _this = this;
      this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.specialArticleDel(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  },
  filters: {
    dateformat(el) {
      return moment(el).format("YYYY/MM/DD");
    }
  },
  computed: {
    secid() {
      return this.$store.state.secid;
    },
    artid() {
      return this.$route.params.artid || 1;
    },
    tid() {
      return this.$route.query.tid || 0;
    },
    noMore() {
      return this.totalCount < this.pageCount * this.page;
    },
    disabledpage() {
      return this.loading || this.noMore;
    }
  }
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width:1200px;
  margin:30px auto;
  padding: 20px;
    box-sizing: border-box;
}
.gobackpage {
  padding: 18px 0 18px 15px;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
}
.topbox {
  line-height: 40px;
  padding: 15px 0 15px 0;
  margin: 0 20px;
  display: flex;
  .titlebox {
    flex: 1;
  }
  .titleh2 {
    font-size: 20px;
    margin-bottom: 10px;
    p {
      display: none;
      font-size: 12px;
      color: #f56c6c;
      line-height: 16px;
      margin-top: 5px;
    }
  }
  .titleh2.notitle {
    p {
      display: block;
    }
  }
  .titleh2inp {
    width: 400px;
  }
  .creatorbox {
    .timg {
      width: 30px;
      height: 30px;
      border-radius: 60px;
      float: left;
      margin-right: 10px;
    }
    .creator {
      font-size: 13px;
      color: #2f95e3;
      margin-right: 10px;
      float: left;
      line-height: 30px;
      text-decoration: underline;
    }
    .timer {
      float: left;
      font-size: 13px;
      line-height: 30px;
      color: #888;
    }
  }
  .iconbox {
    width: 250px;
    & > i {
      float: right;
      margin-left: 15px;
      color: #11adcf;
      font-size: 14px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      &.active {
        color: #3385ff;
      }
    }
  }
}
.textbox {
  padding: 20px 0;
  margin: 0 20px;
  border-bottom: 1px solid #ccc;
  .textp {
    font-size: 14px;
    line-height: 24px;
    text-indent: 2em;
    margin-bottom: 10px;
  }
}
/deep/.collapse {
  i {
    color: #aaa;
  }
}
/deep/.tox-tinymce {
  border-left: none;
  border-right: none;
  border-top: none;
}
.changeedit {
  cursor: pointer;
  text-align: right;
  margin: 20px;
  i {
    color: #11adcf;
  }
}
.savebtn {
  margin-right: -40px;
  height: 30px;
  margin-top: 6px;
  position: relative;
  z-index: 10;
}
/deep/.mavon-editor {
  .v-note-wrapper.shadow {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
  }
}
.teambox {
  padding: 20px;
  margin-top: 20px;
  display: flex;
  .left {
    width: 104px;
    height: 82px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    cursor: pointer;
    color: #666;
    .titleh2 {
      font-size: 16px;
      margin-bottom: 10px;
      color: #222;
    }
    .textp {
      font-size: 14px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .teammember {
      overflow: hidden;
      margin-top: 9px;
      span {
        width: 36px;
        height: 36px;
        border: 1px solid #e9e9e9;
        border-radius: 50px;
        overflow: hidden;
        float: left;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          float: left;
        }
      }
    }
  }
}
.commentbox {
  padding: 0 20px;
  margin-top: 20px;
  .titleh2 {
    font-size: 16px;
    color: #000000;
    line-height: 20px;
    margin-bottom: 10px;
  }
  /deep/.comform {
    margin: 10px 0;
    display: flex;
    .el-form-item__content {
      width: 100%;
    }
    .inputb {
      flex: 1;
    }
    .btnb {
      width: 70px;
    }
  }
}
.commentlist {
  .firstcom {
    display: flex;
    width: 100%;
  }
  /deep/.comform {
    display: none;
  }
  /deep/.comform.active {
    display: flex;
  }
  & > li {
    border-bottom: 1px solid #dcdcdc;
    padding: 15px 0;
    & > .commentlist {
      padding-left: 47px;
      margin-top: 15px;
      & > li {
        border-top: 1px dashed #dcdcdc;
        border-bottom: none;
      }
      & > li:last-child {
        padding-bottom: 0;
      }
      .firstcom {
        margin: 0;
      }
    }
    .imgboxleft {
      width: 32px;
      height: 32px;
      border-radius: 40px;
      border: 1px solid #ccc;
      overflow: hidden;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .textboxcenter {
      flex: 1;
      .titleh2 {
        font-size: 14px;
      }
      .text {
        overflow: hidden;
        font-size: 13px;
        color: #888;
        line-height: 30px;
        .left {
          float: left;
          margin-right: 30px;
        }
      }
      .textp {
        font-size: 13px;
        line-height: 26px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; //溢出隐藏
        .blue {
          color: #1890ff;
        }
      }
      .setbox {
        margin-top: 20px;
        .left {
          color: #888;
          font-size: 12px;
          min-width: 75px;
          display: inline-block;
          cursor: pointer;
        }
        .right {
          color: #11adcf;
          font-size: 18px;
          float: right;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
    .imgboxright {
      width: 205px;
      height: 123px;
      margin: 25px 0 0 25px;
      border-radius: 2px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.is-error {
  .errorp {
    position: absolute;
    color: #f56c6c;
    font-size: 12px;
  }
}
.loadtextp {
  text-align: center;
  padding: 35px 0;
  color: #888;
  font-size: 14px;
}
</style>
